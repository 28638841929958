<template>
  <div v-for="(match, index) in item" :key="index" class="hot-match-item">
    <div class="header">
      <span>{{ match.league_name }}</span>
    </div>

    <div class="content">
      <div class="match">
        <div class="team">
          <BaseImg
            :src="match.teams[0].flag_thumbnail"
            :default-src="'/assets/images/components/common/icon-default-match.svg'"
            class="logo"
            alt="icon flag"
          />
          <p class="name">{{ match.teams[0].name }}</p>
        </div>
        <div class="time">
          <strong>{{ $formatLocalDateTime(match.text_time, 'HH:mm') }}</strong>
          <span>{{ $formatLocalDateTime(match.text_time, 'DD/MM/YYYY') }}</span>
        </div>
        <div class="team">
          <BaseImg
            :src="match.teams[1].flag_thumbnail"
            :default-src="'/assets/images/components/common/icon-default-match.svg'"
            class="logo"
            alt="icon flag"
          />
          <p class="name">{{ match.teams[1].name }}</p>
        </div>
      </div>
      <div class="bet">
        <div class="odds" :class="{ favorite: match.ahFT && match.ahFT?.favorite }">
          <span class="odds__title">TX</span>
          <div class="odds__number">
            <span :class="[getColor(match.ahFT?.aTeam?.odds)]">
              {{ match.ahFT?.aTeam?.odds ? $formatFixNumber(match.ahFT?.aTeam?.odds ?? 0, 2) : '--' }}
            </span>
          </div>
        </div>

        <div
          class="bet__button"
          :class="{ disable: !match.ahFT && !match.overFT }"
          @click.prevent="playKsport(match, false)"
        >
          <span>Cược Ngay</span>
        </div>

        <div class="odds right-odds" :class="{ favorite: match.overFT && match.overFT?.favorite }">
          <span class="odds__title">O/U</span>
          <div class="odds__number">
            <span>
              {{ match.overFT?.aTeam?.odds ? $formatFixNumber(match.overFT?.aTeam?.odds ?? 0, 2) : '--' }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useHotMatch } from '~/composables/useHotMatch'
import BaseImg from '~/components/common/base-img.vue'

const props = defineProps({
  item: {
    type: Object,
    default: () => {}
  }
})

const { $formatFixNumber, $formatLocalDateTime } = useNuxtApp()
const { playKsport } = useHotMatch()

const getColor = (odds) => {
  if (!odds) {
    return ''
  }
  return odds > 0 ? 'color-green' : 'color-red'
}
</script>

<style scoped lang="scss" src="assets/scss/components/mobile/pages/home/sport/hot-match-item.scss"></style>

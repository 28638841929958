import { PAGE } from '~/constants/router'

export const HOME_PROMOTION = [
  {
    id: '1',
    image: '/assets/images/components/desktop/home/promotion/promotion-1.png',
    link: PAGE.PROMOTION
  },
  {
    id: '2',
    image: '/assets/images/components/desktop/home/promotion/promotion-2.png',
    link: PAGE.PROMOTION
  },
  {
    id: '3',
    image: '/assets/images/components/desktop/home/promotion/promotion-3.png',
    link: PAGE.PROMOTION
  },
  {
    id: '4',
    image: '/assets/images/components/desktop/home/promotion/promotion-4.png',
    link: PAGE.PROMOTION
  }
]

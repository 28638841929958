export const LOTTERY = [
  {
    link: '#!',
    image: '/assets/images/components/desktop/home/lottery/thumb-3-mien.png',
    title: 'Xổ số 3 Miền'
  },
  {
    link: '#!',
    image: '/assets/images/components/desktop/home/lottery/thumb-1-phut.png',
    title: 'Xổ số 1 phút'
  },
  {
    link: '#!',
    image: '/assets/images/components/desktop/home/lottery/thumb-xs-vietlott.png',
    title: 'Xổ số 3 phút'
  },
  {
    link: '#!',
    image: '/assets/images/components/desktop/home/lottery/thumb-keno-vietlott.png',
    title: 'Xổ số 5 phút'
  }
]
export const LOBBY_LOTTERY = [
  {
    link: '#!',
    image: '/assets/images/components/desktop/pages/lobby/lobby-games/lottery/thumb-ba-mien.webp',
    imageMobile: '/assets/images/components/desktop/pages/lobby/lobby-games/lottery/thumb-ba-mien.webp',
    title: 'Xổ số 3 Miền'
  },
  {
    link: '#!',
    image: '/assets/images/components/desktop/pages/lobby/lobby-games/lottery/thumb-1p.webp',
    imageMobile: '/assets/images/components/desktop/pages/lobby/lobby-games/lottery/thumb-1p.webp',
    title: 'Xổ số 1 phút'
  },
  {
    link: '#!',
    image: '/assets/images/components/desktop/pages/lobby/lobby-games/lottery/thumb-3p.webp',
    imageMobile: '/assets/images/components/desktop/pages/lobby/lobby-games/lottery/thumb-3p.webp',
    title: 'Xổ số 3 phút'
  },
  {
    link: '#!',
    image: '/assets/images/components/desktop/pages/lobby/lobby-games/lottery/thumb-5p.webp',
    imageMobile: '/assets/images/components/desktop/pages/lobby/lobby-games/lottery/thumb-5p.webp',
    title: 'Xổ số 5 phút'
  }
]

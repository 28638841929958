export type TContact = {
  name: string
  title: string
  icon: string
  link: string
}

export const CONTACTS: TContact[] = [
  {
    name: 'telegram',
    title: 'Telegram',
    icon: '/assets/images/icon/telegram.svg',
    link: ''
  },
  {
    name: 'livechat',
    title: 'Live chat',
    icon: '/assets/images/icon/chat.svg',
    link: ''
  },
  {
    name: 'email',
    title: 'Email',
    icon: '/assets/images/icon/email.svg',
    link: ''
  },
  {
    name: 'congdong',
    title: 'Cộng đồng',
    icon: '/assets/images/icon/people.svg',
    link: ''
  }
]

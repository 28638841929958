<template>
  <div class="sport">
    <SectionTitle
      title="Thể thao 24h"
      icon="/assets/images/components/desktop/icon-sport.png"
      class="title"
      background
    />
    <div class="sport-list">
      <p v-for="(item, index) in SPORTS" :key="index" class="sport-list__item" @click="$router.push(item?.link)">
        <BaseImg class="image" :src="item?.image" :alt="item.name" />
      </p>
    </div>
    <div class="group-content">
      <div class="group-content__matches">
        <SectionTitle title="Trận cầu nổi bật" width="unset" background="none" />
        <div v-if="myLoading" class="hot-match__content--none">
          <div class="ball">
            <BaseImg src="/assets/images/components/desktop/home/sport/icon-ball.svg" class="icon-ball" />
            <BaseImg src="/assets/images/components/desktop/home/sport/bg-ball.svg" class="icon-shadow" />
          </div>
          <p class="note-update">Dữ liệu trận đấu đang được cập nhật</p>
        </div>
        <div v-else-if="hotMatches && hotMatches.length" class="match-container">
          <Swiper v-bind="swiperOption" ref="mySwiperHotMatch">
            <SwiperSlide v-for="(match, index) in hotMatches" :key="index" class="swiper-slide">
              <HotMatchItem :item="match" :data-match_id="match.match_id" />
            </SwiperSlide>
          </Swiper>
          <div class="hotmatch-navigation">
            <div class="swiper-navigation-sport">
              <div :id="`swiper-button-prev-sport`" class="swiper-button-prev">
                <BaseImg
                  src="/assets/images/components/desktop/home/sport/arrow-left.svg"
                  class="icon-arrow"
                  alt="icon arrow"
                />
              </div>
              <div :id="`swiper-button-next-sport`" class="swiper-button-next">
                <BaseImg
                  src="/assets/images/components/desktop/home/sport/arrow-right.svg"
                  class="icon-arrow"
                  alt="icon arrow"
                />
              </div>
            </div>
            <ViewMore link="/lobby-sports/schedules" />
          </div>
        </div>
        <div v-else class="hot-match__content--none">
          <BaseImg src="/assets/images/components/desktop/home/sport/no-data.png" alt="no match data" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Autoplay, Navigation } from 'swiper'
import HotMatchItem from './hot-match-item'
import SectionTitle from '@/components/common/section-title'
import ViewMore from '@/components/common/view-more'
import { SPORTS } from '~/resources/sport'
import { useAppStore } from '@/store/app'
import { useHotMatch } from '~/composables/useHotMatch'
import BaseImg from '~/components/common/base-img.vue'
const { $pinia } = useNuxtApp()
const store = useAppStore($pinia)

const { fetchHotMatch } = useHotMatch()
const { isBotChromeLighthouse } = useAppStore()

const hotMatches = ref([])
const myLoading = ref(false)

const swiperOption = reactive({
  slidesPerView: 1,
  lazy: true,
  autoplay: {
    delay: 10000,
    disableOnInteraction: false
  },
  navigation: {
    nextEl: '#swiper-button-next-sport',
    prevEl: '#swiper-button-prev-sport'
  },
  modules: [Autoplay, Navigation]
})

const getHotMatch = () => {
  myLoading.value = true
  setTimeout(async () => {
    const data = await fetchHotMatch(10)
    hotMatches.value = data || []
    const chunkSize = 2
    hotMatches.value = data?.reduce((acc, val, index) => {
      const chunkIndex = Math.floor(index / chunkSize)
      if (!acc[chunkIndex]) {
        acc[chunkIndex] = []
      }
      acc[chunkIndex].push(val)
      return acc
    }, [])
    myLoading.value = false
  }, 300)
  // Cheat: Improve google speed
  if (isBotChromeLighthouse) {
    hotMatches.value = [...hotMatches.value].slice(0, 3)
  }
}

onMounted(() => {
  getHotMatch()
})
</script>

<style lang="scss" scoped src="assets/scss/components/desktop/pages/home/sport/index.scss"></style>

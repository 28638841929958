export type TInfo = {
  name: string
  title: string
  icon: string
  link: string
}

export const INFO: TInfo[] = [
  {
    name: 'tintuc',
    title: 'Tin Tức',
    icon: '/assets/images/icon/casino-lobby.svg',
    link: ''
  },
  {
    name: 'gioithieu',
    title: 'Giới Thiệu',
    icon: '/assets/images/icon/tiara.svg',
    link: ''
  },
  {
    name: 'dieukhoan',
    title: 'Điều Khoản',
    icon: '/assets/images/icon/security-user.svg',
    link: ''
  },
  {
    name: 'baomat',
    title: 'Bảo Mật',
    icon: '/assets/images/icon/lock.svg',
    link: ''
  }
]

import dayjs from 'dayjs'
import { useLobbyGameApi } from '@/api/lobby-game'
import { useAppStore } from '@/store/app'
import { useLoading } from '@/composables/useLoading'
const MIEN_BAC_ID = 1

const LOTTERY_TYPE = {
  XOSO: 'xs',
  XS_3_SO: '3',
  XS_2_SO: '2'
}

const listLotteryType = [
  { label: 'Xổ Số', lotteryType: LOTTERY_TYPE.XOSO },
  { label: '3 Số', lotteryType: LOTTERY_TYPE.XS_3_SO },
  { label: '2 Số', lotteryType: LOTTERY_TYPE.XS_2_SO }
]

const prizeList = [
  {
    key: 'prize1',
    text: 'Nhất'
  },
  {
    key: 'prize2',
    text: 'Nhì'
  },
  {
    key: 'prize3',
    text: 'Ba'
  },
  {
    key: 'prize4',
    text: 'Tư'
  },
  {
    key: 'prize5',
    text: 'Năm'
  },
  {
    key: 'prize6',
    text: 'Sáu'
  },
  {
    key: 'prize7',
    text: 'Bảy'
  },
  {
    key: 'prize8',
    text: 'Tám'
  }
]

export default () => {
  const targetTime = ref < Date > null
  const countdown = ref(null)

  const getTargetTime = (type = '3mien') => {
    switch (type) {
      case '3mien':
        targetTime.value = new Date()
        targetTime.value.setHours(18)
        targetTime.value.setMinutes(0)
        targetTime.value.setSeconds(0)

        if (targetTime.value < Date.now()) {
          targetTime.value.setDate(targetTime.value.getDate() + 1)
        }

        break
      case '1phut':
        targetTime.value = new Date()
        targetTime.value.setMinutes(targetTime.value.getMinutes() + 1)
        targetTime.value.setSeconds(0)
        break
      default:
        break
    }
  }

  const updateCountdown = () => {
    const now = Date.now()
    const timeRemaining = targetTime.value - now

    if (timeRemaining > 0) {
      const totalSeconds = Math.floor(timeRemaining / 1000)

      const days = Math.floor(totalSeconds / (24 * 60 * 60))
      const hours = Math.floor((totalSeconds % (24 * 60 * 60)) / (60 * 60))
      const minutes = Math.floor((totalSeconds % (60 * 60)) / 60)
      const seconds = totalSeconds % 60

      countdown.value = timeRemaining

      timeObj.value.d = padZero(days)
      timeObj.value.h = padZero(hours)
      timeObj.value.m = padZero(minutes)
      timeObj.value.s = padZero(seconds)
    } else {
      targetTime.value.setDate(targetTime.value.getDate() + 1)
      targetTime.value.setHours(18)
      targetTime.value.setMinutes(0)
      targetTime.value.setSeconds(0)

      startCountdown()
    }
  }

  const startCountdown = () => {
    setInterval(() => {
      updateCountdown()
    }, 1000)
  }

  return {
    updateCountdown,
    startCountdown
  }
}

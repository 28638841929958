import { LOBBY_CASINO_URLS, LOBBY_GAME_URLS } from '~/config/page-url'
import { PAGE } from '~/constants/router'

export const gameCenters = [
  {
    url: LOBBY_GAME_URLS.TABLE_GAME,
    text: 'Table Games',
    objectImg: '/assets/images/components/desktop/pages/home/games/table-game.webp'
  },
  {
    url: LOBBY_GAME_URLS.QUAY_SO,
    text: 'Quay Số',
    objectImg: '/assets/images/components/desktop/pages/home/games/quayso.webp'
  },
  {
    url: LOBBY_GAME_URLS.LO_DE,
    text: 'Lô Đề',
    objectImg: '/assets/images/components/desktop/pages/home/games/lode.webp'
  },
  {
    url: LOBBY_GAME_URLS.NO_HU,
    text: 'Nổ Hũ',
    objectImg: '/assets/images/components/desktop/pages/home/games/nohu.webp',
    jackpot: 'jackpotNohu'
  },
  {
    url: LOBBY_GAME_URLS.GAME_BAI,
    text: 'Game Bài',
    objectImg: '/assets/images/components/desktop/pages/home/games/game-bai.webp'
  },
  {
    url: LOBBY_GAME_URLS.BAN_CA,
    text: 'Bắn Cá',
    objectImg: '/assets/images/components/desktop/pages/home/games/ban-ca.webp',
    jackpot: 'jackpotFishing'
  },
  {
    url: LOBBY_GAME_URLS.SLOTS_GAME,
    text: 'Slots',
    objectImg: '/assets/images/components/desktop/pages/home/games/slots.webp'
  },
  {
    url: LOBBY_GAME_URLS.QUICK_GAMES,
    text: 'Game Nhanh',
    objectImg: '/assets/images/components/desktop/pages/home/games/game-nhanh.webp'
  }
]

export type TLIVE_CASINO = {
  key: string
  title: string
  link: string
  loginRequired: boolean
  newTab: boolean
  keyGame: string
  partner: string
  partner_game_id: string
  deny_info: string
  image: string
}

export const HOME_LIVE_CASINO: TLIVE_CASINO[] = [
  {
    key: 'rikvip',
    title: 'Rikvip',
    link: '/casinoUrl?partnerCode=vivo-sexy&partnerGameId=baccarat',
    loginRequired: true,
    newTab: true,
    keyGame: 'live-casino',
    partner: 'vivo-sexy',
    partner_game_id: 'baccarat',
    deny_info: 'WELCOME',
    image: '/assets/images/components/desktop/home/live-casino/img-rikvip.png'
  },
  {
    key: 'go88',
    title: 'Go88',
    link: '/athena/allbetUrl',
    loginRequired: true,
    newTab: true,
    keyGame: 'live-casino',
    partner: 'allbet',
    partner_game_id: '',
    deny_info: 'WELCOME',
    image: '/assets/images/components/desktop/home/live-casino/img-go88.png'
  },
  {
    key: 'evolution',
    title: 'Evolution',
    link: '/casinoUrl?partnerCode=evo&partnerGameId=baccarat',
    loginRequired: true,
    newTab: true,
    keyGame: 'live-casino',
    partner: 'evo',
    partner_game_id: 'baccarat',
    deny_info: 'WELCOME',
    image: '/assets/images/components/desktop/home/live-casino/img-evo.png'
  },

  {
    key: 'pragmatic',
    title: 'Pragmatic',
    link: '/casinoUrl?partnerCode=pragmatic&partnerGameId=all',
    loginRequired: true,
    newTab: true,
    keyGame: 'live-casino',
    partner: 'pragmatic',
    partner_game_id: 'all',
    deny_info: 'WELCOME',
    image: '/assets/images/components/desktop/home/live-casino/img-pragmatic.png'
  },
  {
    key: 'mg',
    title: 'MG Live',
    link: '/casinoUrl?partnerCode=mg&partnerGameId=MPBaccarat',
    loginRequired: true,
    newTab: true,
    keyGame: 'live-casino',
    partner: 'mg',
    partner_game_id: 'MPBaccarat',
    deny_info: 'WELCOME',
    image: '/assets/images/components/desktop/home/live-casino/img-ezugi.png'
  },
  {
    key: 'mg',
    title: 'MG Live',
    link: '/casinoUrl?partnerCode=mg&partnerGameId=MPBaccarat',
    loginRequired: true,
    newTab: true,
    keyGame: 'live-casino',
    partner: 'mg',
    partner_game_id: 'MPBaccarat',
    deny_info: 'WELCOME',
    image: '/assets/images/components/desktop/home/live-casino/img-mg-live.png'
  }
]

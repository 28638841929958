<template>
  <div class="hot-match-item">
    <div v-for="(match, index) in item" :key="index" class="list-match">
      <div class="list-match__item">
        <div class="team">
          <div class="team-left">
            <div class="match-name" :class="{ 'strong-team': match.hTeam?.rate < 0 }">
              <div class="match-name__img">
                <div class="image">
                  <BaseImg
                    :src="match.teams[0].flag_thumbnail"
                    :default-src="'/assets/images/components/common/icon-default-match.svg'"
                    alt="flag thumbnail"
                  />
                </div>
              </div>
              <p>{{ match.teams[0].name }}</p>
            </div>
          </div>
          <div class="team-center">
            <span>{{ match.league_name }}</span>
            <div class="team-center__time">
              {{
                `${$formatLocalDateTime(match.text_time, 'DD/MM')} - ${$formatLocalDateTime(match.text_time, 'HH:mm')}`
              }}
            </div>
            <div class="team-center__action">
              <div
                class="button ksport"
                :class="{ disable: !match.ahFT && !match.overFT }"
                @click.prevent="playKsport(match, false)"
              >
                <span>CƯỢC</span>
              </div>
            </div>
          </div>
          <div class="team-right">
            <div class="match-name" :class="{ 'strong-team': match.aTeam?.rate < 0 }">
              <div class="match-name__img">
                <BaseImg
                  :src="match.teams[1].flag_thumbnail"
                  :default-src="'/assets/images/components/common/icon-default-match.svg'"
                  alt="flag thumbnail"
                />
              </div>
              <p>{{ match.teams[1].name }}</p>
            </div>
          </div>
        </div>
        <div class="bet">
          <div class="odds" :class="{ favorite: match.ahFT && match.ahFT?.favorite }">
            <span class="title">Chấp</span>
            <span :class="[getColor(match.ahFT?.hTeam?.odds)]">
              {{ match.ahFT?.hTeam?.odds ? $formatFixNumber(match.ahFT?.hTeam?.odds ?? 0, 2) : '--' }}
            </span>
            <span>
              {{ match.ahFT?.hTeam?.rate ? $formatFixNumber(match.ahFT?.hTeam?.rate ?? 0, 2) : '--' }}
            </span>
            <span :class="[getColor(match.ahFT?.aTeam?.odds)]">
              {{ match.ahFT?.aTeam?.odds ? $formatFixNumber(match.ahFT?.aTeam?.odds ?? 0, 2) : '--' }}
            </span>
          </div>
          <div class="odds odds-2" :class="{ favorite: match.overFT && match.overFT?.favorite }">
            <span class="title">T/X</span>
            <span :class="[getColor(match.overFT?.hTeam?.odds)]">
              {{ match.overFT?.hTeam?.odds ? $formatFixNumber(match.overFT?.hTeam?.odds ?? 0, 2) : '--' }}
            </span>
            <span>
              {{ match.overFT?.hTeam?.rate ? $formatFixNumber(match.overFT?.hTeam?.rate ?? 0, 2) : '--' }}
            </span>
            <span :class="[getColor(match.overFT?.aTeam?.odds)]">
              {{ match.overFT?.aTeam?.odds ? $formatFixNumber(match.overFT?.aTeam?.odds ?? 0, 2) : '--' }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useHotMatch } from '@/composables/useHotMatch'
const props = defineProps({
  item: {
    type: Object,
    default: () => {}
  }
})

const { $formatFixNumber, $formatLocalDateTime } = useNuxtApp()
const { playKsport } = useHotMatch()

const getColor = (odds) => {
  if (!odds) {
    return null
  }
  return odds > 0 ? 'color-green' : 'color-red'
}
</script>

<style scoped lang="scss" src="assets/scss/components/desktop/pages/home/sport/hot-match-item.scss"></style>

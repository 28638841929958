<template>
  <div class="home">
    <LazyHydrationWrapper when-visible>
      <div class="home-top">
        <BannerSection />
      </div>
      <GameCenter />
      <section class="section-list">
        <Sport />
        <Lottery />
        <LiveCasino />
      </section>
      <HomePromotion />
      <HomeSeo />
    </LazyHydrationWrapper>
  </div>
</template>

<script setup lang="ts">
import { LazyHydrationWrapper } from 'vue3-lazy-hydration'
import HomePromotion from './promotion'
import HomeSeo from './seo'
import LiveCasino from './live-casino/index.vue'
import Lottery from './lottery/index.vue'
import Sport from './sport/index.vue'
import GameCenter from '~/components/desktop/pages/home/game-center/index.vue'

const BannerSection = defineAsyncComponent(() => import('@/components/desktop/pages/home/hero-banner/index.vue'))

onMounted(() => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  })
})
</script>

<style lang="scss" scoped src="assets/scss/components/desktop/pages/home/index.scss" />

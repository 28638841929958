<template>
  <div class="base-datepicker">
    <Datepicker
      ref="datepicker"
      v-model="currentValue"
      :format="format"
      :auto-apply="true"
      :enable-time-picker="false"
      :day-names="dateName"
      :clearable="clearable"
      :close-on-scroll="closeOnScroll"
      :placeholder="placeHolder"
      :disabled="disabled"
      :min-date="minDate.toString()"
      :max-date="maxDate.toString()"
      six-weeks
      locale="vi"
      :format-locale="vi"
      month-name-format="long"
      calendar-class-name="dp-custom-calendar"
      input-class-name="dp-custom-input"
      :teleport-center="false"
      teleport=".base-datepicker"
      :class="{ open: openDatePicker, close: !openDatePicker }"
      :on-click-outside="() => closeModalDatePickker()"
      @open="onMenuOpen"
      @closed="closeMenu"
    >
      <template #month-year="{ month, handleMonthYearChange }">
        <div class="custom-month-year-component">
          <span class="custom-icon" @click="handleMonthYearChange(false)">
            <i class="icon-left" />
          </span>
          <div>Tháng {{ month + 1 }}</div>
          <span class="custom-icon" @click="handleMonthYearChange(true)">
            <i class="icon-right" />
          </span>
        </div>
      </template>
      <template #input-icon>
        <span class="icon-down icon-arrow-select input-slot-image" />
      </template>
    </Datepicker>
  </div>
</template>

<script setup>
import dayjs from 'dayjs'
import '@vuepic/vue-datepicker/dist/main.css'
import Datepicker from '@vuepic/vue-datepicker'
import { DATE_FORMATS } from '~/config/constant'
import { useAppStore } from '@/store/app'
const { $config, $pinia } = useNuxtApp()
const store = useAppStore($pinia)
const { setOpenDatePicker } = store
const datepicker = ref(null)

const props = defineProps({
  onMenuOpen: {
    type: Function,
    default: () => {}
  },
  modelValue: {
    type: [String, Date, Object],
    default: null
  },
  displayFormat: {
    type: String,
    default: DATE_FORMATS.SHORT_DATE_FORMAT
  },
  placeHolder: {
    type: String,
    default: 'Chọn ngày'
  },
  clearable: {
    type: Boolean,
    default: false
  },
  closeOnScroll: {
    type: Boolean,
    default: false
  },
  disabled: {
    type: Boolean,
    default: false
  },
  minDate: {
    type: [String, Date, Object],
    default: ''
  },
  maxDate: {
    type: [String, Date, Object],
    default: ''
  },
  openDatePicker: {
    type: Boolean,
    default: false
  }
})

const dateName = ref(['T2', 'T3', 'T4', 'T5', 'T6', 'T7', 'Cn'])
const emit = defineEmits(['update:modelValue'], value)
const openDate = ref(true)
const openDatePicker = computed(() => {
  return store.openDatePicker
})

const closeModalDatePickker = () => {
  if (datepicker) {
    setOpenDatePicker(false)
    datepicker.value.closeMenu()
  }
}

const closeMenu = () => {
  openDate.value = false
  setOpenDatePicker(false)
}

const currentValue = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  }
})

const format = (date) => {
  return date
    ? dayjs(date, props.displayFormat).locale('vi').format(props.displayFormat)
    : dayjs().locale('vi').format(DATE_FORMATS.DATE_FORMAT_PICKET)
}
</script>
<style lang="scss" src="assets/scss/components/common/base-datepicker.scss"></style>

<template>
  <div class="section-sport">
    <div class="section-sport__header">
      <p class="title">Trực tiếp thể thao</p>
      <ViewMore :link="PAGE.SPORTS" hide-icon />
    </div>

    <div class="group-content">
      <div class="group-content__matches">
        <div v-if="isLoading" class="hot-match__content--none">
          <div class="ball">
            <BaseImg src="/assets/images/components/desktop/home/sport/icon-ball.svg" class="icon-ball" />
            <BaseImg src="/assets/images/components/desktop/home/sport/bg-ball.svg" class="icon-shadow" />
          </div>
          <p class="note-update">Dữ liệu trận đấu đang được cập nhật</p>
        </div>
        <div v-else-if="futureMatches?.length">
          <Swiper v-bind="swiperOption" ref="mySwiperHotMatch">
            <SwiperSlide v-for="(match, index) in futureMatches" :key="index" class="swiper-slide">
              <HotMatchItem :item="match" :data-match_id="match.match_id" />
            </SwiperSlide>
          </Swiper>
        </div>
        <div v-else class="hot-match__content--none">
          <BaseImg src="/assets/images/components/desktop/pages/home/sports/no-data.png" alt="no data" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Autoplay, Pagination } from 'swiper'
import HotMatchItem from './hot-match-item.vue'
import { SPORTS_MOBILE } from '~/resources/sport'
import { useAppStore } from '~/store/app'
import { useHotMatch } from '~/composables/useHotMatch'
import ViewMore from '@/components/common/view-more'
import { PAGE } from '~/constants/router'

const { fetchHotMatch, getLink_K_SportHome, getLink_C_SportHome, getLink_P_SportHome } = useHotMatch()
const { isBotChromeLighthouse } = useAppStore()
const src = ref('')

const futureMatches = ref([])
const isLoading = ref(false)
const router = useRouter()

const swiperOption = reactive({
  slidesPerView: 1,
  lazy: true,
  autoplay: {
    delay: 100000,
    disableOnInteraction: false
  },
  pagination: {
    clickable: true
  },
  modules: [Autoplay, Pagination]
})

const fetchFutureMatch = () => {
  isLoading.value = true
  setTimeout(async () => {
    const data = await fetchHotMatch(6)
    futureMatches.value = data || []
    const chunkSize = 1
    futureMatches.value = data?.reduce((acc, val, index) => {
      const chunkIndex = Math.floor(index / chunkSize)
      if (!acc[chunkIndex]) {
        acc[chunkIndex] = []
      }
      acc[chunkIndex].push(val)
      return acc
    }, [])
    isLoading.value = false
  }, 300)
  // Cheat: Improve google speed
  if (isBotChromeLighthouse) {
    futureMatches.value = [...futureMatches.value].slice(0, 3)
  }
}

const openGame = async (item) => {
  if (item?.label === 'lobby') {
    router.push(item?.link)
    return
  }
  if (item?.label === 'K') {
    src.value = await getLink_K_SportHome(item?.link)
  } else if (item?.label === 'C') {
    src.value = await getLink_C_SportHome(item?.link)
  } else {
    src.value = await getLink_P_SportHome(item?.link)
  }
  setTimeout(() => {
    window.open(src.value, '_blank')
  }, 100)
}

onMounted(async () => {
  await fetchFutureMatch()
})
</script>

<style lang="scss" scoped src="assets/scss/components/mobile/pages/home/sport/index.scss"></style>

<template>
  <div class="base-select" :class="[className]">
    <p v-if="label" class="base-select__label">{{ label }}</p>
    <div
      v-click-outside="hideOption"
      class="base-select__wrap"
      :class="{
        focused: currentValue,
        'input-error': $v.currentValue.$error,
        disabled: disabled,
        show: isShowOptions
      }"
      @click="isShowOptions = !isShowOptions"
    >
      <span v-if="!isShowButtonChange" class="icon-select-down">
        <span class="icon-down icon-arrow-select" />
      </span>
      <div v-if="isShowButtonChange" class="change-button">
        <span class="change-button__content">Thay đổi</span>
        <span :class="['change-button__icon', { show: isShowOptions }]">
          <BaseImg :src="srcIconSelectDown" alt="icon select down" class="select" />
        </span>
      </div>
      <div v-if="isShowOptions" class="base-select__options-wrapper">
        <ul class="base-select__options">
          <li
            v-for="item in items"
            :key="getValue(item)"
            :class="{
              active: currentValue && currentValue === getValue(item),
              'show-icon-selected': isShowIconSelected
            }"
            @click.stop="chooseOption(item)"
          >
            <BaseImg v-if="item.icon" :src="item.icon" :alt="getText(item)" />
            <p>{{ getText(item) }}</p>
          </li>
        </ul>
      </div>
      <div class="base-select__inner">
        <div v-if="!currentValue" class="base-select__empty">
          <BaseImg v-if="placeholderIcon" :src="placeholderIcon" alt="icon empty" />
          <p>{{ placeholder }}</p>
        </div>
        <div v-else class="base-select__value">
          <BaseImg v-if="iconShow" :src="iconShow" :alt="`icon ${textShow}`" />
          <p>{{ textShow }}</p>
        </div>
      </div>
    </div>
    <p v-if="$v.currentValue.$invalid && $v.$dirty" class="error">
      <template v-if="!$v.currentValue.required">
        {{ errorRequired ? errorRequired : `Vui lòng chọn ${label ? label.toLowerCase() : ''}` }}
      </template>
    </p>
  </div>
</template>

<script setup>
import useValidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'

const props = defineProps({
  modelValue: {
    type: [String, Number],
    default: ''
  },
  name: {
    type: String,
    default: ''
  },
  className: {
    type: String,
    default: ''
  },
  textField: {
    type: String,
    default: ''
  },
  valueField: {
    type: String,
    default: ''
  },
  label: {
    type: String,
    default: ''
  },
  items: {
    type: Array,
    default: () => []
  },
  required: {
    type: Boolean,
    default: false
  },
  disabled: {
    type: Boolean,
    default: false
  },
  errorRequired: {
    type: String,
    default: ''
  },
  placeholder: {
    type: String,
    default: ''
  },
  placeholderIcon: {
    type: String,
    default: ''
  },
  getObjectValue: {
    type: Boolean,
    default: false
  },
  srcIconSelectDown: {
    type: String,
    default: '/assets/images/components/common/icon-down.svg'
  },
  isShowButtonChange: {
    type: Boolean,
    default: false
  },
  isShowIconSelected: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['update:modelValue', 'change', 'getItem'])
const isShowOptions = ref(false)

const currentValue = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    if (props.getObjectValue) {
      const selectedItem = props.items.find((item) => item[props.valueField] === value)
      emit('change', selectedItem)
    } else {
      emit('change', value)
    }
    emit('update:modelValue', value)
  }
})
const textShow = computed(() => {
  if (props.textField && props.valueField) {
    const data = props.items.find((item) => item[props.valueField] === currentValue.value)
    return data ? data[props.textField] : ''
  }
  return currentValue.value
})
const iconShow = computed(() => {
  if (props.textField && props.valueField) {
    const selectedItem = props.items.find((item) => item[props.valueField] === currentValue.value)
    return selectedItem ? selectedItem.icon : ''
  }
  return ''
})
const rules = computed(() => {
  return {
    currentValue: props.required ? required : true
  }
})

const $v = useValidate(rules, currentValue)

const validate = () => {
  $v.value.$touch()
  if (!$v.value.$invalid) {
    return props.modelValue
  }
}
const resetValidate = () => {
  $v.value.$reset()
}
const getValue = (item) => {
  return props.valueField ? item[props.valueField] : item
}
const getText = (item) => {
  return props.textField ? item[props.textField] : item
}
const resetOption = () => {
  currentValue.value = ''
  isShowOptions.value = false
}
const chooseOption = (item) => {
  currentValue.value = props.valueField ? item[props.valueField] : item
  isShowOptions.value = false
  emit('getItem', item)
}
const hideOption = () => {
  isShowOptions.value = false
}

defineExpose({
  validate,
  resetValidate,
  hideOption
})
</script>

<style lang="scss" scoped src="assets/scss/components/common/base-selector.scss"></style>

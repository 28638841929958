<template>
  <div class="lottery-item">
    <BaseImg :src="item.image" alt="image" />
    <div class="content">
      <p class="content__title">{{ item.title }}</p>
      <p class="content__description">Lần quay số tiếp theo bắt đầu trong</p>
      <div class="content__time-row">
        <div class="time">00h</div>
        <p class="separator">:</p>
        <div class="time">00m</div>
        <p class="separator">:</p>
        <div class="time">00s</div>
      </div>
      <div class="content__button">Cược Ngay</div>
    </div>
  </div>
</template>

<script setup>
import { HOME_LOTTERY_MB } from '~/resources/home'
import useLotteryCountdown from '~/composables/useLotteryCountdown'

const props = defineProps({
  item: {
    type: Object,
    default: () => {}
  }
})

const { startCountdown } = useLotteryCountdown()
</script>

<style lang="scss" scoped src="assets/scss/components/mobile/pages/home/lottery/lottery-item.scss"></style>

<script setup lang="ts">
import ViewMore from '~~/components/common/view-more.vue'
import { PAGE } from '~~/constants/router'
import { HOME_LIVE_CASINO } from '~~/resources/liveCasino'

const { navigationCheckLoggedInAndOpenGame } = useNavigationGame()
</script>

<template>
  <div class="live-casino">
    <div class="live-casino__header">
      <p class="title">Live Casino</p>
      <ViewMore :hide-icon="true" :link="`${PAGE.CASINO}/all`" />
    </div>
    <div class="live-casino__list">
      <div v-for="item in HOME_LIVE_CASINO" :key="item.key" class="item">
        <div class="image">
          <div class="overlay">
            <button class="overlay__button" @click.prevent="navigationCheckLoggedInAndOpenGame(item)">CƯỢC NGAY</button>
          </div>
          <BaseImg :src="item.image" :alt="item.title" />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" src="assets/scss/components/mobile/pages/home/live-casino/index.scss"></style>

<template>
  <div id="casino" class="casino">
    <div class="casino-header">
      <SectionTitle title="Live casino" icon="/assets/images/components/desktop/icon-live-casino.png" background />
      <ViewMore :link="PAGE.CASINO" />
    </div>
    <div class="casino-list">
      <div v-for="(item, index) in HOME_LIVE_CASINO" :key="index" class="casino-list__item">
        <div class="casino-list__item-image">
          <div class="game-item__overlay">
            <button class="game-item__overlay--btn" @click.prevent="navigationCheckLoggedInAndOpenGame(item)">
              CƯỢC NGAY
            </button>
          </div>
          <base-img class="image" :src="item?.image" :alt="item?.title" />
        </div>
        <div class="casino-list__item-text">{{ item?.title }}</div>
      </div>
    </div>
  </div>
</template>

<script setup>
import useNavigationGame from '~/composables/useNavigationGame'
import SectionTitle from '@/components/common/section-title'
import ViewMore from '@/components/common/view-more'
import { HOME_LIVE_CASINO } from '~/resources/home'
import { PAGE } from '~/constants/router'

const { navigationCheckLoggedInAndOpenGame } = useNavigationGame()
</script>

<style lang="scss" scoped src="assets/scss/components/desktop/pages/home/live-casino/index.scss"></style>

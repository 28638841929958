<template>
  <div class="info-item">
    <CommonBaseImg :src="item.icon" :alt="item.name" />
    <p class="info-item__title">
      {{ item.title }}
    </p>
  </div>
</template>

<script setup lang="ts">
import { TInfo } from '~~/resources/info'
const CommonBaseImg = defineAsyncComponent(() => import('~/components/common/base-img.vue'))

defineProps<{ item: TInfo }>()
</script>

<style lang="scss" scoped src="assets/scss/components/mobile/pages/home/info/info-item.scss"></style>

<script setup lang="ts">
import { TContact } from '~~/resources/contact'

const CommonBaseImg = defineAsyncComponent(() => import('~/components/common/base-img.vue'))

defineProps<{
  item: TContact
}>()
</script>

<template>
  <div class="info-item">
    <CommonBaseImg :src="item.icon" :alt="item.name" />
    <p class="info-item__title">
      {{ item.title }}
    </p>
  </div>
</template>

<style lang="scss" scoped src="/assets/scss/components/mobile/pages/home/contact/contact-item.scss"></style>

<template>
  <section id="section-games-mb" class="section-games">
    <div v-for="(item, index) in HOME_GAMES_MB" :key="index" class="section-games__item" @click="clickGameItem(item)">
      <BaseImg :src="item.image" alt="game" />
      <p class="section-games__item-title">{{ item.title }}</p>
      <div v-if="sumJackpot && sumJackpot[item.jackpot] > 0" class="jackpot">
        <AnimateNumber :number="sumJackpot[item.jackpot]" :show-coin="false" />
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useAppStore } from '@/store/app'
import BaseImg from '~/components/common/base-img.vue'
import { HOME_GAMES_MB } from '~/resources/home'
import { useJackpot } from '~/composables/game/useJackpot'
import AnimateNumber from '~/components/common/animate-number.vue'
const router = useRouter()
const { $pinia } = useNuxtApp()
const store = useAppStore($pinia)
const { sumJackpot } = storeToRefs(store)
const { fetchSumJackpot } = useJackpot()

const clickGameItem = (item) => {
  router.push(item.link)
}

onMounted(() => {
  fetchSumJackpot()
})
</script>

<style lang="scss" scoped src="assets/scss/components/mobile/pages/home/games/index.scss"></style>
